import React, { memo, FC, MouseEvent } from 'react';
import styled from '@emotion/styled';
import { rgba } from 'color2k';
import { Button } from '$components/elements/button';
import { SvgIcon } from '$components/elements/svg-icon';

export type IVideoCurtainProps = {
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    thumbnail?: string;
    showIcon?: boolean;
};

export const VideoCurtain: FC<IVideoCurtainProps> = memo(
    ({ thumbnail, onClick, showIcon = true }: IVideoCurtainProps): JSX.Element => {
        return (
            <>
                {!!thumbnail && (
                    <StyledVideoCurtain>
                        <VideoCurtainButton
                            variant="custom"
                            type="button"
                            onClick={onClick}
                            aria-label={'Afspil video'}
                        >
                            <Thumbnail src={thumbnail} alt="Youtube thumbnail" />
                            {showIcon && <VideoCurtainIcon svg="youtube" />}
                        </VideoCurtainButton>
                    </StyledVideoCurtain>
                )}
            </>
        );
    }
);

const StyledVideoCurtain = styled.div({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 3,
    background: rgba(0, 0, 0, 0.2),
});

const Thumbnail = styled.img({
    height: 'auto',
    width: '100%',
});

const VideoCurtainButton = styled(Button)(({ theme }) => ({
    height: '100%',
    width: '100%',
    border: 'none',

    ':hover': {
        [`${VideoCurtainIcon}`]: {
            color: theme.colors.primaryTheme,
        },
    },
}));

const VideoCurtainIcon = styled(SvgIcon)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    margin: 'auto',
    height: '48px',
    width: '68px',
});
